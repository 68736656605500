import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchJoinUSData = createAsyncThunk('join/fetchJoinUSData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/join-us`);
  return response.data;
});

const joinUsSlice = createSlice({
  name: 'join',
  initialState: {
    data: [],
    status: 'idle', 
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJoinUSData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJoinUSData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchJoinUSData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default joinUsSlice.reducer;
