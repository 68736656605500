import React from "react";
import { Box } from "@mui/material";
import Header from "./header/Header";
import ResponsiveFooter from "./footer/Footer";

const MainLayout = ({ children }) => {
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    }}
  >
    <Box
      sx={{
        position: "sticky",
        top: 0,  

        zIndex: 1000,  
        backgroundColor: "white",  
        mb: 5,
      }}
    >
      <Header />
    </Box>
  
    <main
      style={{
        flex: 1,
        marginTop: "40px",
      }}
    >
      {children}
    </main>
  
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        pr: { xs: 1, sm: 3, md: 18 },
        pl: { xs: 1, sm: 3, md: 18 },
      }}
    >
      {/* Background Box */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#042B5D",
          zIndex: -2,
        }}
      />
    </Box>
  
    <ResponsiveFooter />
  </Box>
  );
};

export default MainLayout;
