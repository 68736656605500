import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "../../src/redux/slices/HomeSlice";
import aboutReducer from "../../src/redux/slices/AboutSlice";
import serviceReducer from "../../src/redux/slices/ServicesSlice";
import tamhidReducer from "../../src/redux/slices/TamhidSpaceSlice";

import joinUSReducer from "../redux/slices/JoinUSSlice";

const store = configureStore({
  reducer: {
    home: homeReducer,
    about: aboutReducer,
    services: serviceReducer,
    tamhidSpace: tamhidReducer,
    joinUs: joinUSReducer,
  },
});

export default store;
