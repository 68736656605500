import { Button } from "@mui/material";
import {  useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { useTheme } from "@emotion/react";

const LanguageToggleButton = ({ onLocaleChange }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const theme = useTheme();

  useEffect(() => {
    const savedLocale = localStorage.getItem("locale") || "ar";
    const savedLanguage = localStorage.getItem("language") || "ar";

    if (savedLocale !== savedLanguage) {
      localStorage.setItem("locale", savedLanguage);
      localStorage.setItem("language", savedLanguage);
    }

    i18n.changeLanguage(savedLanguage);
    document.dir = savedLanguage === "ar" ? "rtl" : "ltr";
    document.documentElement.lang = savedLanguage;
  }, [i18n]);

  const toggleLanguage = () => {
    const newLanguage = locale === "ar" ? "en" : "ar";
    
    i18n.changeLanguage(newLanguage);
    document.dir = newLanguage === "ar" ? "rtl" : "ltr";
    document.documentElement.lang = newLanguage;

    localStorage.setItem("locale", newLanguage);
    localStorage.setItem("language", newLanguage);

    if (onLocaleChange) {
      onLocaleChange(newLanguage);
    }
  };

  return (
    <Button
      onClick={toggleLanguage}
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "8px",
        color: theme.palette.primary.body,
        fontWeight: "600",
        fontSize: "16px",
      }}
    >
      <LanguageIcon
        sx={{
          marginRight: locale === "ar" ? 0 : 1,
          marginLeft: locale === "en" ? 0 : 1,
        }}
      />
      {locale === "en" ?"AR" :"EN"  }
    </Button>
  );
};

export default LanguageToggleButton;
