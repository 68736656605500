import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from '../src/redux/store'; 
import { englishTheme, arabicTheme } from './styles/theme';
import MainLayout from './components/layout/MainLayout';
import ScrollToTop from './components/shared/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './pages/NotFound';
import Loader from './components/shared/Loader';
import ToastNotification from './components/shared/ToastNotification';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/Services'));
const TamhidSpace = lazy(() => import('./pages/TamhidSpace'));
const ContactUS = lazy(() => import('./pages/ContactUS'));
const JoinUS = lazy(() => import('./components/JoinUS'));

function App() {
  const { i18n } = useTranslation();
  const currentTheme = i18n.language === 'ar' ? arabicTheme : englishTheme;

  return (
    <Provider store={store}>
        <ThemeProvider theme={currentTheme}>
          <CssBaseline />
          <HelmetProvider>
            <Router>
              <ScrollToTop />
              <MainLayout>
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="about" element={<About />} />
                      <Route path="services" element={<Services />} />
                      <Route path="tamhidspace" element={<TamhidSpace />} />
                      <Route path="ContactUS" element={<ContactUS />} />
                      <Route path="joinus" element={<JoinUS />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Suspense>
                </ErrorBoundary>
              </MainLayout>
            </Router>
            <ToastNotification />
          </HelmetProvider>
        </ThemeProvider>
    </Provider>
  );
}

export default App;
