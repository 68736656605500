import { createTheme } from "@mui/material/styles";

const sharedPalette = {
  primary: {
    main: "#000000",
    label: "#000000",
    title: "#1C1F21",
    body: "#383E43",
    negative: "#FFFFFF",
    light:"#c2c6cc"
  },
  secondary: {
    main: "#c2185b",
  },
  background: {
    default: "#ffffff",
  },
  text: {
    primary: "#212121",
    secondary: "#757575",
  },
};

export const englishTheme = createTheme({
  direction: "ltr",
  palette: {
    ...sharedPalette,
    background: {
      default: sharedPalette.background.default,
    },
  },
  typography: {
    fontFamily: "'Somar Sans', sans-serif",
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontWeight: 900,
    },
    body1: {
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
  },
});

export const arabicTheme = createTheme({
  direction: "rtl",
  palette: {
    ...sharedPalette,
    background: {
      default: sharedPalette.background.default,
    },
  },
  typography: {
    fontFamily: "'Somar Sans', sans-serif",
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontWeightLight: 300,
    h1: {
      fontWeight: 900,
    },
    body1: {
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
  },
});
